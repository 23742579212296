<template>
<div class='page'>
    <div class="content">
    <div class="content1">
         <div class="left">
            <div class="H1">{{assurance1Data.H1}}</div>
            <div class="list" v-if="assurance1Data.list.length>0">
                <div class="item" v-for="(item,index) in assurance1Data.list" :key="index">
                    <div class="rad"></div>
                     <div class="title">{{item.name}}</div>
                </div>
               
            </div>
            <div class="info" v-html="assurance1Data.info"></div>


            
        </div>
        <div class="rig"><img :src="assurance1Data.img" alt=""></div>

    </div>
       
   
   
    </div>
</div>
</template>
<script>
export default {
data() {
return {
   
}
},
methods: {},
mounted() {},
created() {},
props:{assurance1Data:{}},
watch:{ },
components:{}
}
</script>
<style scoped>
.rad{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #DA251D
}

.item{
    display: flex;
    align-items: center;
    padding: 10px 0;

}
.page{
    width: 100%;
    margin: 50px 0
}

.title{
    margin-left:20px ;
 font-size: 16px;
 font-weight: bold
}


.list{
  padding: 30px 0
}
.H1{
font-size: 34px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #DA251D;
line-height: 42px;
}
.info{
    font-size: 16px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #000000;
line-height: 25px;
}
img{
    width: 100%;
}
.H1{
    margin-bottom:20px 
}
.left{
    width: 36%;
    min-width: 36%;
}
.rig{
    max-width:560px ;
    min-width: 330px;
}
 
.content{
    width: 1200px;
margin: auto;
}
.content1{

    
    display: flex;
    justify-content: space-between;
    align-items: center
}
 @media screen and (max-width: 760px) {
     .left{
         width: 100%;
         margin-bottom:40px 
     }
     .content{
         width: 90%;
         min-width: 330px
     }
     .content1{
         
         display:block
     }


     .H1{
         font-size: 25px
     }
     .rig{
         margin: auto
     }

}
@media screen and (min-width: 760px) and (max-width: 1020px) {
    .content{
        width: 90%;
    }
    .H1{
        font-size: 28px
    }
    .info{
        font-size: 14px
    }
    .rig{
        width: 55%;
    }


}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
    .content{
        width: 90%;
    }
    .rig{
        width: 60%;
    }



}
@media screen and (min-width: 1350px) {
    
}
</style>