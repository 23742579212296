<template>
<div class='page'>
    <div class="banner">
            <div class="w"> 
                <div class="info">
                    <div class="title">Quality assurance</div>
                    <p class="title1">Our team is dedicated to making sure all parts you order are delivered on time and meet your quality expectations.</p>
                    
                  
                </div>
            </div>
        </div>
  <myhead :hbg="hbg"></myhead>
  <bread :bbg="bbg"></bread> 
  <assurance></assurance>
  <standards></standards>
  <assurance1 :assurance1Data="assurance1Data1"></assurance1>
   <assurance1 :assurance1Data="assurance1Data2"></assurance1>
  
  
</div>
</template>
<script>
import assurance from './assurance.vue'
import standards from './standards.vue'
import assurance1 from './assurance1.vue'
import myhead from '../../components/myHead.vue'

import bread from '../../components/bread.vue'

export default {
data() {
return {
   assurance1Data1:{
        img:require('../../assets/img/assurance111.png'),
        H1:'CERTIFICATIONS',
        info:'Material certifications, data sheets, and test reports are available on request.<br> Email sales info@hlhcnc.com for details',
        list:[{
            name:'UKAS ISO 9001: 2015',

        },{
            name:'UKAS ISO 14001:2015',

        },{
            name:'ISO/IEC 27001: 2013',

        },{
            name:'IATF 16949:2016',

        },]

    },
        hbg:{
        bg:'#ffffff'

    },
    bbg:{
 bg:'#ffffff',
 color:'#000000'
    },
      assurance1Data2:{
        img:require('../../assets/img/assurance122.png'),
        H1:'Resolving quality issues',
        info:'Should your order not meet specifications, you will be able to raise any issues within 5 business days of receiving your parts. Our order fulfillment team will resolve these issues as quickly as possible, typically within 1-3 business days.',
        list:[]

    }
}
},
methods: {},
mounted() {},
created() {},
props:{},
watch:{ },
components:{
  myhead,
  assurance,
  standards,
  assurance1,
  bread,
}
}
</script>
<style scoped lang="scss">
.page{
  
}
 .banner{
        background: url('~@/assets/img/qualityAssurancebanner.png') no-repeat;
        
  
        width: 100%;
        // height: 560px;
        transition: 0.25s;
    
        padding-top: 70px;
        .w{
          // width: 1200px;
              display: flex;
 
      height: 100%;
      display: flex;
      // justify-content: center;
 
      align-items: center;

 
    margin: auto;
        }
    
        
        .title1{
            // font-size: 60px;
            margin-bottom: 2vw;
            transition: 0.25s;
            color: #ffffff;
            opacity: 0.7;
          
      
        }
        .info {
      
    
            font-size: 24px;
            color: #333333;
            // margin-top: 30px;    
            .item{
                display: flex;
                align-items: center;
            
            }
            >p {
                // margin-bottom: 30px;
                img {
                    vertical-align: middle;
                    margin-right: 20px;
                    // width: 36px;
                    // height: 36px;
                    max-width: 36px;
                    min-width: 25px;
                 
                }
                span {
                    vertical-align: middle;
                }
            }
            >.title {
                // font-size: 48px;
                // margin-bottom: 0;
                color: white;
                font-weight: bold;
           transition: 0.25s;
            }
        }
    }
    
@media screen and (max-width: 760px) {   //最小  min-width="330px"
    
    .w{
        width: 90%;
        min-width: 330px;
    }
    .title{
        font-size: 40px;
        font-weight: bold;
        margin-bottom:7vw 
    } 
    .title1{
           font-size: 17px;
        font-weight: bold;
        width: 70vw;
        line-height: 21px;
     
          // line-height: 25px;
    }
    img{
        width: 26px
    }
    .itemT{
        font-size: 14px;
    }
    p{
        margin-bottom:10px ;
    }
    .banner{
        height: 500px;
        
          background-position: 48% 43% !important;
    }
     .btn{
        font-size: 14px;
        margin-left: 48px;
    }
}

//平板
@media screen and (min-width: 760px) and (max-width: 1020px) {

    .w{
        width: 90%;
    }
    .title{
        font-size: 60px;
        margin-bottom: 54px;
    }
    .title1{
            font-size: 22px;
              line-height: 36px;
          width:50vw;
    }
    img{
        width: 30px;
    }
    .itemT{
        font-size: 22px;
    }
    p{
        margin-bottom:30px ;
    }
    .banner{
        height: 620px;
        background-position: center !important;
    }
    .btn{
        margin-left: 64px;
        font-size: 16px;
    }
    .info{
        margin-top: 30px;
    }
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
    .w{
        width: 90%;
        margin: auto
    }
    .title{
        font-size: 80px;
        margin-bottom: 54px;
    }
   .title1{
        font-size: 22px;
        width:45vw;
          line-height: 36px;
    }
    img{
        width: 36px;}
        .itemT{
            font-size: 24px;
        }  p{
            margin-bottom:30px ;
        }
        .banner{
            height: 620px;
            background-position: center !important;
        }
        .btn{
            margin-left: 64px;
            font-size: 16px;
        }
        .info{
            margin-top: 30px;
        }
}



@media screen and (min-width: 1350px) {  //最大限制   width="1280px"
  
  .w{
    width: 1200px;
  }
    .title{
        font-size: 100px;
        margin-bottom: 54px;
    }
    .title1{
           font-size: 24px;
        width: 650px;
          line-height: 36px;
    }
    .itemT{
        font-size: 24px;
    }
    img{
        width: 36px;}
        p{
            margin-bottom:30px ;
        }
        .banner{
            height: 620px;
            background-position: center !important;
        }
        .btn{
            margin-left: 64px;
            font-size: 16px;
        }
        .info{
            margin-top: 30px;
        }
}
</style>