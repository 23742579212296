<template>
<div class='page'>
    <div class="content">
        <div class="left">
            <div class="H1">HLH CNC manufacturing standards</div>
            <div class="info">We follow DIN-2678-1 standards for CNC Machining.</div>
            <div class="list">
                <div class="item" v-for="(item,index) in standardsData.dataList" :key="index">
                    <div class="sum1">{{item.name1}}</div>
                    <div class="sum2">{{item.name2}}</div>
                    <div class="sum3">{{item.name3}}</div>
                </div>
                 
            </div>
        </div>
          <div class="rig"><img src="../../assets/img/standards1.png" alt=""></div>
    </div>
</div>
</template>
<script>
export default {
data() {
return {
    standardsData:{
        H1:'',
        info:'',
        dataList:[{
            name1:'Limits for nominal size',
            name2:'Plastics (DIN-2678-1- m)',
            name3:'Metals DIN-2678-1- f)'
        },{
            name1:'0.5mm* to 3mm',
            name2:'±0.1mm',
            name3:'±0.05mm'
        },{
            name1:'Over 3mm to 6mm ',
            name2:'±0.1mm',
            name3:'±0.05mm'
        },{
            name1:'Over 6mm to 30mm',
            name2:'±0.2mm',
            name3:'±0.1mm'
        },{
            name1:'Over 30mm to 120mm',
            name2:'±0.3mm',
            name3:'±0.15mm'
        },{
            name1:'Over 120mm to 400mm',
            name2:'±0.5mm',
            name3:'±0.2mm'
        },{
            name1:'Over 400mm to 1000mm',
            name2:'±0.8mm',
            name3:'±0.3mm'
        },{
            name1:'Over 1000mm to 2000mm',
            name2:'±1.2mm',
            name3:'±0.15mm'
        },{
            name1:'Over 2000mm to 4000mm',
            name2:'±2mm',
            name3:'/'
        },]
    }
}
},
methods: {},
mounted() {},
created() {},
props:{},
watch:{ },
components:{}
}
</script>
<style scoped>
.page{
    width: 100%;
    padding-top: 149px ;
    padding-bottom:110px; 
    background: #232323
}
.item{
    display: flex;
    padding: 16px 0;
    border-bottom:1px solid #DA251D 
    
}
.sum1{
    width: 38%;
    font-size: 16px
}
.sum2{
    width: 29%;
    font-size: 16px
}
.sum3{
    width: 29%;
    font-size: 16px
}
.list{
    margin: 60px 0
}
.info{
    font-size: 16px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #FFFFFF;
line-height: 24px;
 
}
.H1{
    font-size: 34px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #DA251D;
margin-bottom:20px; 

}
.rig{
    max-width: 400px;
    min-width: 330px
}
.left{
    width: 60%;
    color: #ffffff
}
img{
    width: 100%;
}

.content{
    width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between
}
 @media screen and (max-width: 760px) {

.page{
    padding-top:12vw ;
        padding-bottom:0vw; 
}
.content{
    width: 90%;
    margin: auto;
    min-width: 330px;
    display: flex;
    flex-direction: column-reverse
}
.rig{
    width: 100%;
    margin: auto
}
 
 .left{
     width: 100%;
     margin: 50px 0
 }
 .H1{
     font-size: 25px
 }
   .sum1,.sum2,.sum3{
        width: 33%;
    font-size: 13px

}
.list{
    margin: 5vw 0
}

}
@media screen and (min-width: 760px) and (max-width: 1020px) {
.page{
    padding-top:12vw ;
        padding-bottom:12vw; 
}
.content{
    width: 90%;
    display: flex;
    flex-direction: column-reverse;
    margin: auto
}
.rig{
    width: 100%;
    margin: auto
}
.list{
    margin: 5vw 0
}
.H1{
    font-size: 30px
}
 
 .left{
     width: 100%;
     margin: 50px 0
 }
   .sum1,.sum2,.sum3{
        width: 33%;
    font-size: 13px

}


}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
    .content{
        width: 90%;
        margin: auto

    }
    .item{
        padding: 14px 0
    }
    .left{
        width: 56%;
    }
    .rig{
        width: 40%;
    }
    .sum1,.sum2,.sum3{
        width: 33%;
    font-size: 13px

}

}
@media screen and (min-width: 1350px) {
    
}


</style>