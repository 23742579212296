<template>
<div class='page'>
    <div class="content">
    <div class="content1">
         <div class="left">
            <div class="H1">Quality assurance</div>
            <div class="info">Our team is dedicated to making sure all parts you order are delivered on time and meet your quality expectations.</div>
        </div>
        <div class="rig"><img src="../../assets/img/assurance1.png" alt=""></div>

    </div>
       
       <div class="list">  
           <div class="item"><img src="../../assets/img/assurancemodel1.png" alt=""></div>
            <div class="item"><img src="../../assets/img/assurancemodel2.png" alt=""></div>
             <div class="item"><img src="../../assets/img/assurancemodel3.png" alt=""></div>
              <div class="item"><img src="../../assets/img/assurancemodel4.png" alt=""></div>
       </div>
    </div>
</div>
</template>
<script>
export default {
data() {
return {}
},
methods: {},
mounted() {},
created() {},
props:{},
watch:{ },
components:{}
}
</script>
<style scoped>
.page{
    width: 100%;
}
.item{
   width: 280px;
   margin-bottom:15px 
}
.H1{
font-size: 34px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #DA251D;
line-height: 42px;
}
.info{
    font-size: 16px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #000000;
line-height: 25px;
}
img{
    width: 100%;
}
.H1{
    margin-bottom:20px 
}
.left{
    width: 36%;
    min-width: 36%;
}
.rig{
    max-width:720px ;
    min-width: 330px;
}
.list{
    display: flex;
    justify-content: space-between;
    margin: 28px 0;
    flex-wrap: wrap
}
.content{
    width: 1200px;
margin: auto;
}
.content1{

    
    display: flex;
    justify-content: space-between;
    align-items: center
}
 @media screen and (max-width: 760px) {
     .left{
         width: 100%;
         margin-bottom:40px 
     }
     .content{
         width: 90%;
         min-width: 330px
     }
     .content1{
         
         display:block
     }
     .item{
         width: 48%;
     }
     .H1{
         font-size: 25px
     }

}
@media screen and (min-width: 760px) and (max-width: 1020px) {
    .content{
        width: 90%;
    }
    .H1{
        font-size: 28px
    }
    .info{
        font-size: 14px
    }
    .rig{
        width: 55%;
    }
    .item{
        width: 23%;
    }
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
    .content{
        width: 90%;
    }
    .rig{
        width: 60%;
    }
    .item{
        width: 23%;
    }

}
@media screen and (min-width: 1350px) {
    
}
</style>